<template>
  <div/>
</template>

<script>
  import {
    apiLogout,
  } from '@/functions/api'

  export default {
    name: 'logout',
    methods: {
      handleCreated() {
        this.logout()
      },

      async logout() {
        await this.$store.dispatch('auth/logout')
        return apiLogout()
          .then((res) => {
            if (!res.ok) {
              if (res.error.code === 503) {
                // что делать? сообщение об ошибке?
              }
            }

            return this.$router.push('login')
          })
      },
    },
    created() {
      this.handleCreated()
    },
  }
</script>
